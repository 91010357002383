.main-flex {
  min-height: calc(100vh - 150px);
  display: flex;
  align-items: flex-start;
  column-gap: 30px;
  margin: 150px 70px 0px;
  /* margin-top:100px ; */
}
.column-flex {
  display: flex;
  flex-direction: column;
  flex: 50%;
  width: 50%;
  row-gap: 20px;
  margin-top: 40px;
}
.main-img {
  /* flex: 50%; */
  width: 50%;
}
.join-link{
  width: auto;
  margin: 0 auto 0 0 ;
}

.main-heading {
  color: white;
  font-size: 50px;
  font-weight: 700;
  line-height: 1.1;
}

.info-line-1 {
  background: linear-gradient(123.22deg, #2a174f 55.03%, #fe0190 207.53%);
  color: white;
  /* width: auto; */
  margin: 10px auto 0 0;
  text-align: left;
  padding: 10px;
  border-radius: 10px;
}
.info-line-2 {
    cursor: pointer;
  background: rgba(23, 0, 52, 0.65);
  border: 1px solid #434343;
  border-radius: 20px;

  margin: 10px auto 0 0;
  text-align: left;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  column-gap: 10px;
  font-size: 20px;
  font-weight: 600;
  position: relative;
}

.sharedisk-small-img {
  width: 40px;
}
.arrow-right {
  width: 34px;
}
.betatag {
  background: linear-gradient(98.83deg, #de94e7 -27.02%, #6e69f6 83.49%);
  border-radius: 20px;
  font-size: 10px;
  padding: 5px;
  position: absolute;
  top: -10px;
  left: 17%;
}
.info-line-mobile{
  display: none;
}
.socials.mobile{
  display: none;
}
@media screen and (max-width: 600px) {

.main-flex{
  flex-direction: column;
  margin: 100px 20px 0px;

}
.main-img{
  width: 100%;
}

.column-flex{
  width: 100%;
}
.main-heading{
  font-size: 30px;
}
.info-line-1{
  font-size: 14px;
}

.info-line-2{
  font-size: 18px;
  margin: 0px ;
  padding: 10px 15px;
  column-gap: 10px;
  width: 100%;
  white-space: nowrap;
}
.info-line-1.homepage{
  display: none;
}
.info-line-mobile{
  display: block;
  color: white;
  font-weight: 400;
}
.socials.mobile{
  display: flex;
  margin: 20px auto 20px 0;
  column-gap: 15px;
}
.socials.mobile img{
  width: 30px;
}
.main-heading{
  margin-top: 15px;
}
}