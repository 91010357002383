.blur-tile.help {
  display: flex;
  column-gap: 30px;
  padding: 4rem 6rem;
}
.large-title.help {
  font-size: 45px;
}
.flex-child {
  flex: 30%;
  /* background-color: black; */
  /* height: 200px; */
}
.flex-child.first {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
.custom-collapsible {
  color: white;
  padding: 10px 15px 10px 25px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.collapsible-body {
  line-height: 1.4;
  color: white;
}

.Collapsible__contentInner {
  padding: 20px;
}
.Collapsible {
  background: #3d266b;
  border: 1px solid #434343;
  border-radius: 20px;
}

.flex-child.accordion {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

@media screen and (max-width: 600px) {
  .blur-tile.help {
    flex-direction: column;
    padding: 0;
    row-gap: 20px;
  }
  .flex-child.accordion{
    row-gap: 20px;
  }

  .large-title.help{
    font-size: 30px;
  }
  .para-1.help{
margin-top: 15px;
text-align: center;
  }
  .flex-child.first.help{
    align-items: center;
  }
}


@media screen and (max-width: 600px) {
}