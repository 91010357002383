.flex-title {
  display: flex;
  color: white;
  margin: 30px 0px 15px;
}
.title-1.custom {
  margin: 0;
}
.tools-flex {
  display: flex;
  column-gap: 30px;
  margin-top: 30px;
}
.info-line-2.pirate {
  white-space: nowrap;
  font-size: 16px;
}

.info-line-2.copyright {
  white-space: nowrap;
  font-size: 14px;
  padding: 15px 30px;
}
.info-line-2.copyright .betatag {
  left: 4%;
}
.email {
  color: #b8aaff;
}
.email-info-mobile{
  display: inline-block;
  color: white;
  margin-top: 20px;
}
@media screen and (max-width: 600px) {
  .title-1.custom{
    font-size: 14px;
  }
  .flex-title{
    font-size: 14px;
  }
  .info-line-2.copyright{
    display: none;
  }
}