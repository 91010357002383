.blur-tile.contactus {
  height: 75vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blur-tile.privacy {
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact-ui{
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 20px;
    width: 70%;
    text-align: center;
}

.email-box {
  background: rgba(23, 0, 52, 0.65);
  border: 1px solid #434343;
  border-radius: 20px;
  padding: 15px 20px;
  display: flex;
  column-gap: 10px;
  align-items: center;
  color: white;
  font-size: 20px;

}

.sub-text{
    color: white;
}

@media screen and (max-width: 600px) {
  .contact-ui{
    width: 95%;
  }
  .email-box span{
    font-size: 12px;
    text-align: left;
  }
  .sub-text{
    font-size: 14px;
  }
}
.sub-text.pricing{
  text-align: center;
    display: flex;
    row-gap: 10px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}