.footer-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  /* position: sticky; */
  position: fixed; 
  bottom: 20px;
   left: 0;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(23, 0, 52, 1);
  border: 1px solid #434343;
  border-radius: 20px;
  height: 50px;
  width: 100%;
  margin: 0px 40px; 
  padding: 0px 20px;
  color: white;
  font-size: 14px;
}

.socials {
  margin-right: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 25px;
}
.socials img {
  width: 30px;
}

@media screen and (max-width: 600px) {
  .socials {
    display: none;
  }
  .footer {
    margin: 0px 20px;
    font-size: 12px;
    height: 30px;
    border-radius: 10px;
    justify-content: center;
    padding: 20px 0px 30px;
    border: none;

  }
  .footer-container{
    position: relative;
    bottom: 0;
  }

}
