.navbar-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  /* position: absolute; */
  position: fixed;
  top: 20px;
  z-index: 100;
}

.navbar {
  position: relative;
  background: rgba(23, 0, 52, 0.65);
  border: 1px solid #434343;
  border-radius: 20px;
  height: 80px;
  width: 100%;
  margin: 0px 40px;
  padding: 0px 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.flex-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav-img {
  height: 30px;
  color: white;
}

.nav-links {
  display: flex;
  align-items: center;
  column-gap: 30px;
}
.nav-links a {
  color: white;
  text-decoration: none;
  font-size: 14px;
}
.menu-burger {
  display: none;
}
.nav-links-mobile {
  display: none;
}


.mobile {
  display: none;
}
.sophia.desktop{
  width: 40px;
}
@media screen and (max-width: 600px) {
  .nav-links {
    display: none;
  }
  .menu-burger {
    display: block;
  }
  .nav-links-mobile {
    transition: height 400ms linear 0s;
    overflow: hidden;
    display: flex;
  }
  .mobile-container {
    display: flex;
    row-gap: 20px;
    flex-direction: column;
    margin-top: 20px;
  }
  .nav-links-mobile a {
    color: white;
    text-decoration: none;
  }
  .navbar {
    height: auto;
    padding: 0.7rem;
    margin: 0px 20px;
    backdrop-filter: blur(12.5px);
  }
  .mobile{
    display: block;
  }
  .sophia.mobile{
    width: 33px;
  }
  .menu-burger{
width: 25px;
  }
}

