.info-line-2.creator {
  margin-top: 40px;
  width: fit-content;
}
.main-heading-2 {
  color: white;
  font-size: 25px;
  font-weight: 500;
  line-height: 1.1;
}
.column-flex.creator {
  margin-top: 0px;
}

.main-img.creator {
  width: 45%;
  flex: none;
}
.read-faq a {
  color: white;
  font-weight: 500;
}

@media screen and (max-width: 600px) {
  .main-heading-2 {
    font-size: 16px;
    font-weight: 500;
  }
  .main-img.creator {
    display: none;
  }
  .main-img.creator.mobile {
    display: block;
    width: 100%;
  }
  .info-line-1.creator {
    display: none;
  }
  .info-line-2.creator {
    margin-top: 10px;
  }
}
