.tile-container {
  width: 100%;
  margin-top: 120px;
  margin-bottom: 100px;
}

.blur-tile {
  background: rgba(38, 15, 73, 0.35);
  border: 1px solid rgba(218, 218, 218, 0.31);
  backdrop-filter: blur(12.5px);
  border-radius: 10px;

  /* width: 100%; */
  margin: 0px 40px;
  padding: 4rem 10rem;
  /* width: 100%; */
  text-align: left;
}
.large-title {
  color: white;
  font-weight: 700;
  font-size: 60px;
}

.title-1 {
  color: white;
  font-weight: 500;
  margin-top: 30px;
  margin-bottom: 15px;
  text-align: left;
  border-bottom: 1px solid white;
  margin: 30px 0 15px 0;
  width: fit-content;
}
.para-1 {
  color: white;
  text-align: left;
}

.ceo-box {
  background: rgba(38, 15, 73, 0.35);
  border: 1px solid rgba(218, 218, 218, 0.31);
  backdrop-filter: blur(12.5px);

  border-radius: 10px;
  padding: 1.5rem 0rem 1.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: left;
  width: 30%;
  column-gap: 10px;
}
.name-info {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}
.ceo-name {
  color: #f2cbff;
  font-weight: 700;
}
.ceo {
  color: white;
}
.twitter-link {
  font-size: 12px;
}
.twitter-link a {
  color: #76bdff;
}
.ceo-boxes {
  display: flex;
  margin-top: 30px;
  column-gap: 20px;
}
.ceo-img{
  width: 56px;
}

@media screen and (max-width: 600px) {
  .blur-tile {
    margin: 0px 20px;
    padding: 0rem;
    backdrop-filter: none;
    border: none;
    background: none;
  }
  .tile-container {
    margin-top: 100px;
  }
  .large-title {
    font-size: 30px;
  }
  .para-1{
    font-size: 14px;
  }
  .title-1{
    font-size: 16px;
  }
  .ceo-boxes{
    flex-direction: column;
    row-gap: 20px;
  }
  .ceo-box{
    width: 90%;
  }

}
